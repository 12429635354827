<template>
  <UiMenu
    v-model="menu"
    top
    left
    offset-y
    :nudge-top="8"
    :min-width="156"
    :close-on-content-click="false"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" color="accent" :disabled="disabled">
        <IconArrowUp width="14" class="mr-2"/>
        More Actions
      </UiBtn>
    </template>

    <v-list nav dense color="gray-10">
      <v-list-item
        :disabled="!abilityToRequestReview"
        @click="requestReview"
      >
        <v-list-item-title class="text-captions-1">
          <IconSendMessage width="15" class="mr-2 gray-60--text"/>
          Request review
        </v-list-item-title>
      </v-list-item>

      <v-list-item :disabled="!getPermission($route.params.project_id).design['can-share-design'] || true">
        <v-list-item-title class="text-captions-1">
          <IconShare width="15" class="mr-2 gray-60--text"/>
          Share files
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="selectedItems.length > 1"
        :disabled="!noDesignFiles"
        :href="downloadLink"
        @click="compressFileNotification"
      >
        <v-list-item-title class="text-captions-1">
          <IconDownload width="15" class="mr-2 gray-60--text"/>
          Download files
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="selectedItems.length === 1"
        :disabled="!noDesignFiles"
        @click="downloadFile"
      >
        <v-list-item-title class="text-captions-1">
          <template v-if="!fileLoading">
            <IconDownload width="15" class="mr-2 gray-60--text"/>
            Download files
          </template>
          <v-flex v-else class="d-flex justify-center align-center">
            <v-progress-circular
              :size="24"
              width="2"
              color="accent"
              indeterminate
            />
          </v-flex>
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :disabled="!abilityToReview || checkIfDisabled($config.project.status.rejected)"
        @click="approveOrReject($config.project.status.rejected)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCancel width="15" class="mr-2 gray-60--text"/>
          Request adjustments
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :disabled="!abilityToReview || checkIfDisabled($config.project.status.approved)"
        @click="approveOrReject($config.project.status.approved)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCheck width="15" class="mr-2 gray-60--text"/>
          Approve design
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$vuetify.breakpoint.smAndDown && !canUnarchive"
        :disabled="!getPermissionByModule('can-archive-design') || archiveDisabled"
        @click="updateStatus($config.project.status.archived)"
      >
        <v-list-item-title class="text-captions-1">
          <IconArchive width="15" class="mr-2 gray-60--text"/>
          Archive design
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$vuetify.breakpoint.smAndDown && canUnarchive"
        :disabled="!getPermissionByModule('can-archive-design')"
        @click="updateStatus($config.project.status.in_progress)">
      >
        <v-list-item-title class="text-captions-1">
          <IconArchive width="15" class="mr-2 gray-60--text"/>
          Archive design
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$vuetify.breakpoint.smAndDown"
        :disabled="!getPermission($route.params.project_id).design['can-delete-style-design']"
        @click="deleteItems"
      >
        <v-list-item-title class="text-captions-1">
          <IconDelete width="15" class="mr-2 gray-60--text"/>
          Delete design
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'DesignItemsBulkActionsMenu',
  components: {
    IconArrowUp: () => import('@/components/icons/IconArrowUp'),
    IconSendMessage: () => import('@/components/icons/IconSendMessage'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconCheck: () => import('@/components/icons/IconCheck'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconShare: () => import('@/components/icons/IconShare'),
    IconArchive: () => import('@/components/icons/IconArchive'),
    IconDelete: () => import('@/components/icons/IconDelete'),

    UiMenu,
    UiBtn,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      fileLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProject',
      'getActiveDesignModule',
      'getProjectDesigns',
      'getPermission',
      'getProjectPermissionData',
    ]),
    selectedItems() {
      return this.getProjectDesigns.filter(design => design.checked);
    },
    itemsWithFiles() {
      return this.selectedItems.filter(des => des.items.length);
    },
    abilityToRequestReview() {
      return (this.itemsWithFiles.some(design => design.items[0].status !== this.$config.project.status.approved) && this.getPermissionByModule('can-request-review-design')) || this.isManagerOrOwner;
    },
    abilityToReview() {
      return this.itemsWithFiles.filter(design => design.items[0].request_reviews.find(user => user.user_id === this.getAuthId)).length > 0 || this.isManagerOrOwner;
    },
    archiveDisabled() {
      if (!this.noDesignFiles) {
        return true;
      }

      const archiveStatusFiles = this.selectedItems.filter(des => des.items.length && des.items[0].status === this.$config.project.status.archived);
      const noDesignFiles = this.selectedItems.filter(des => !des.items.length);

      if (archiveStatusFiles.length + noDesignFiles.length === this.selectedItems.length) {
        return true;
      }

      return archiveStatusFiles.length === this.selectedItems.length;
    },
    canUnarchive() {
      if (!this.selectedItems.filter(des => des.items.length).length) {
        return false;
      }
      return this.selectedItems.every(des => des.items.length && des.items[0].status === this.$config.project.status.archived);
    },
    noDesignFiles() {
      return !!this.itemsWithFiles.length
    },
    downloadLink() {
      const downloadFilesIdx = this.selectedItems.filter(des => des.items.length).map(design => design.items[0].id);
      if (!downloadFilesIdx.length) return;

      const itemsArr = downloadFilesIdx.map((item, idx) => idx === 0 ? `?items[]=${item}` : `&items[]=${item}`).join('');
      return `${process.env.VUE_APP_API_URL}/projects/${this.$route.params.project_id}/modules/${this.getActiveDesignModule.id}/design/download-zip${itemsArr}`
    },
    isManagerOrOwner() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager);
    }
  },
  methods: {
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(this.getActiveDesignModule.slug);
    },
    compressFileNotification() {
      this.menu = false;
      this.$toast.open({
        message: 'Your files are being compressed',
        type: 'success',
        position: 'top-right'
      });
    },
    checkIfDisabled(status) {
      if (!this.noDesignFiles) {
        return true;
      }

      const oneStatusFiles = this.selectedItems.filter(des => des.items.length && des.items[0].status === status);
      const noDesignFiles = this.selectedItems.filter(des => !des.items.length);

      if (oneStatusFiles.length + noDesignFiles.length === this.selectedItems.length) {
        return true;
      }

      return oneStatusFiles.length === this.selectedItems.length;
    },
    async downloadFile() {
      if (this.fileLoading) return;
      this.fileLoading = true;

      const file = this.selectedItems[0].items[0];

      const image = await fetch(file.file.download_url || file.file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.file.download_file_name || file.file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
      this.menu = false;
    },
    requestReview() {
      if (!this.abilityToRequestReview) return;

      const updateFiles = this.selectedItems.filter(design => design.items.length && (design.items[0].status !== this.$config.project.status.approved || (design.items[0].status === this.$config.project.status.approved && this.isManagerOrOwner)));
      if (!updateFiles.length) return;

      this.$store.dispatch('openModal', {
        modalName: 'requestReviewModal',
        data: {
          files: [...updateFiles],
          module_id: this.getActiveDesignModule.id,
        },
      });

      this.menu = false;
    },
    approveOrReject(status) {
      this.$store.dispatch('openModal', {
        modalName: 'approveRejectModal',
        data: {
          status,
          files: this.selectedItems.reduce((acc, design) => {
            if (design.items.length && (design.items[0].request_reviews.find(user => user.user_id === this.getAuthId) || this.isManagerOrOwner)) {
              acc.push(design.items[0]);
            }
            return acc;
          }, []),
        },
        handlers: {
          onUpdate: () => {
            this.$emit('updateDesignsData')
          },
        }
      });
    },
    deleteItems() {
      if (!this.getPermission(this.$route.params.project_id).design['can-delete-style-design']) return;

      this.$emit('deleteItems');
      this.menu = false;
    },
    updateStatus(status) {
      if (status === this.$config.project.status.archived && !this.getPermissionByModule('can-archive-design')) return;
      let filteredDesign = this.selectedItems.filter(des => des.items.length && des.items[0].status !== status);

      const updateFilesIdx = filteredDesign.map(design => design.items[0].id);
      if (!updateFilesIdx.length) return;

      const designs = this.getProjectDesigns.map(design => design.items.length && updateFilesIdx.includes(design.items[0].id) ? {...design, loading: true} : design);
      this.$store.dispatch('setProjectDesigns', designs);
      this.menu = false;

      this.$emit('setStatus', {
        items: updateFilesIdx,
        status,
      });
    }
  }
}
</script>
