<template>
  <div class="design-bulk-actions d-flex align-center">
    <div class="design-bulk-actions-counter text-captions-1">
      <strong class="font-weight-semi-bold accent--text text-body">({{ selectedItems.length }})</strong>
      <span class="font-weight-semi-bold ml-1" style="margin-top: -2px;">Item(s) Selected</span>

      <div class="d-flex align-center mt-1" v-if="$vuetify.breakpoint.smAndUp">
        {{ getProjectDesigns.length }} Total Projects
        <span class="ml-1 font-weight-semi-bold">({{ getProjectDesigns.length }} Displayed)</span>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-3"
               @click="toggleAll(true)">
          Select All
        </UiBtn>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-2"
               @click="toggleAll(false)">
          Deselect
        </UiBtn>
      </div>
    </div>
    <div class="d-flex align-center ml-auto">
      <div class="d-flex align-center" v-if="$vuetify.breakpoint.mdAndUp">
        <UiBtn outlined color="accent"
               :disabled="!getPermission($route.params.project_id).design['can-delete-style-design'] || disableAll"
               @click="deleteItems">
          <IconDelete width="14" class="mr-2"/>
          Delete
        </UiBtn>
        <UiBtn v-if="canUnarchive" outlined color="accent" class="ml-4"
               :disabled="!getPermissionByModule('can-archive-design') || disableAll"
               @click="updateStatus($config.project.status.in_progress)">
          <IconArchive width="14" class="mr-2"/>
          Unarchive
        </UiBtn>
        <UiBtn v-else outlined color="accent" class="ml-4"
               :disabled="!getPermissionByModule('can-archive-design') || archiveDisabled || disableAll"
               @click="updateStatus($config.project.status.archived)">
          <IconArchive width="14" class="mr-2"/>
          Archive
        </UiBtn>
        <UiBtn outlined color="accent" class="mx-4"
               :disabled="!getPermission($route.params.project_id).design['can-share-design'] || disableAll || true">
          <IconShare width="14" class="mr-2"/>
          Share
        </UiBtn>
      </div>
      <DesignItemsBulkActionsMenu @deleteItems="deleteItems" @setStatus="setStatus" :disabled="disableAll" @updateDesignsData="$emit('updateDesignsData')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import DesignItemsBulkActionsMenu from "./DesignItemsBulkActionsMenu";

export default {
  name: 'DesignItemsBulkActions',
  components: {
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconArchive: () => import('@/components/icons/IconArchive'),
    IconShare: () => import('@/components/icons/IconShare'),

    UiBtn,
    DesignItemsBulkActionsMenu,
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProject',
      'getProjectDesignsFilters',
      'getProjectDesigns',
      'getActiveDesignModule',
      'getPermission',
      'getProjectPermissionData',
      'getAllProjectStyles',
    ]),
    selectedItems() {
      return this.getProjectDesigns.filter(design => design.checked);
    },
    archiveDisabled() {
      if (!this.selectedItems.filter(des => des.items.length).length) {
        return true;
      }
      const archiveStatusFiles = this.selectedItems.filter(des => des.items.length && des.items[0].status === this.$config.project.status.archived);
      const noDesignFiles = this.selectedItems.filter(des => !des.items.length);

      if (archiveStatusFiles.length + noDesignFiles.length === this.selectedItems.length) {
        return true;
      }
      return archiveStatusFiles.length === this.selectedItems.length;
    },
    canUnarchive() {
      if (!this.selectedItems.filter(des => des.items.length).length) {
        return false;
      }
      return this.selectedItems.every(des => des.items.length && des.items[0].status === this.$config.project.status.archived);
    },
    activeModuleWorkflowOpenTypeSlug() {
      return this.$config.project.workflowOpenTypesById[this.getActiveDesignModule?.workflow_type]
    },
    disableAll() {
      return !this.getProject?.scheme?.settings[this.activeModuleWorkflowOpenTypeSlug] && this.selectedItems.some(design => !design.ready_to_work);
    }
  },
  methods: {
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(this.getActiveDesignModule.slug);
    },
    deleteItems() {
      if (!this.getPermission(this.$route.params.project_id).design['can-delete-style-design']) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete <br> <div class="d-flex justify-center"><p class="text-no-wrap overflow-hidden text-overflow-ellipsis">${this.selectedItems.length === 1 ? '“'+this.selectedItems[0].title+'“' : 'selected styles'}</p>?</div>`,
          confirmBtnText: 'Delete'
        },
        handlers: {
          onConfirm: async () => {
            const deleteItemsIdx = this.selectedItems.map(design => design.id);

            const designs = this.getProjectDesigns.map(design => deleteItemsIdx.includes(design.id) ? {...design, loading: true} : design);
            this.$store.dispatch('setProjectDesigns', designs);
            this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.projectModuleDesign.deleteMultiple(this.$route.params.project_id, this.getActiveDesignModule.id, {styles: deleteItemsIdx});

              const designs = this.getProjectDesigns.filter(design => !deleteItemsIdx.includes(design.id));
              const allStyles = this.getAllProjectStyles.filter(style => !deleteItemsIdx.includes(style.id));

              if (designs.length === 0) {
                this.$store.state.ProjectDesign.noCreatedStyles = true;
              }
              this.$store.dispatch('setProjectDesigns', designs);
              this.$store.dispatch('setAllProjectStyles', allStyles);
            } catch (error) {
              console.error(error);
            } finally {
              const designs = this.getProjectDesigns.map(design => deleteItemsIdx.includes(design.id) ? {...design, loading: false} : design);
              this.$store.dispatch('setProjectDesigns', designs);

              // we need to load the project to check if the module has files
              // if module don't have any files, it will be available to remove
              const project = await this.$api.project.get(this.getProject.id);
              this.$store.dispatch('setProject', project.data);
              this.$store.dispatch('setDesignModules', project.data.modules);
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          }
        }
      });
    },
    updateStatus(status) {
      if (!this.getPermissionByModule('can-archive-design')) return;

      const updateFilesIdx = this.selectedItems.filter(des => des.items.length && des.items[0].status !== status).map(design => design.items[0].id);
      if (!updateFilesIdx.length) return;

      const designs = this.getProjectDesigns.map(design => design.items.length && updateFilesIdx.includes(design.items[0].id) ? {...design, loading: true} : design);
      this.$store.dispatch('setProjectDesigns', designs);
      this.setStatus({status, updateFilesIdx});
    },
    async setStatus(data) {
      const {status, updateFilesIdx} = data;

      try {
        const res = await this.$api.projectModuleDesign.updateMultipleItemsStatus(this.$route.params.project_id, this.getActiveDesignModule.id, {
          status: status,
          items: updateFilesIdx,
        });

        const designs = this.getProjectDesigns.map(design => {
          if (design.items.length) {
            const file = res.data.find(item => item.id === design.items[0].id);
            return file ?
              {...design, loading: false, checked: false, items: [{...design.items[0], status, updated_at: file.updated_at}]} :
              design;
          }
          return {...design, checked: false};
        });

        await this.$store.dispatch('setProjectDesigns', designs);
      } catch (error) {
        const designs = this.getProjectDesigns.map(design => design.loading ? {...design, loading: false} : design);
        await this.$store.dispatch('setProjectDesigns', designs);
        console.error(error);
      }
    },
    toggleAll(val) {
      const designs = this.getProjectDesigns.map(design => ({...design, checked: val}));
      this.$store.dispatch('setProjectDesigns', designs);
    },
  },
}
</script>

<style scoped lang="scss">
.design-bulk-actions {
  .design-bulk-actions-counter {
    .v-btn::before {
      display: none;
    }
  }
}
</style>
