<template>
  <div :class="`card-wr ${size}`">
    <UiGridCard
      v-for="design in getProjectDesigns"
      :key="design.id"
      :item="design"
      :itemStatus="design.items.length ? design.items[0].status : null"
      :selected="design.checked"
      :selectable="false"
      @onSelect="value => onSelect({id: design.id, checked: value})"
      :to="go(design)"
    >
      <template v-slot:header>
        <div class="d-flex justify-end py-3 px-3">
          <UiSelectedIndicator
            :selected="design.checked"
            @click.prevent="onSelect({id: design.id, checked: !design.checked})"
          />
        </div>
      </template>
<!--      <template #badges>-->
<!--        <UiBadge color="primary" v-if="design.is_new">New</UiBadge>-->
<!--        <UiBadge color="in_review" v-if="design.review_needed">Review Needed</UiBadge>-->
<!--      </template>-->
      <template v-slot:image>
        <div class="card-image d-flex align-center justify-center flex-grow-1">
          <UIImage class="fill-width fill-height" v-if="!design.items.length || !design.items[0].file" :name-path="`default-img.png`"/>
          <img v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(design.items[0].file.original_name))" :src="getFileThumbnails(design.items[0].file.thumbnails) || design.items[0].file.url" alt="" class="fill-width fill-height object-cover">
          <UiFileIcon v-else width="30" class="ma-auto" :extension="getFileName(design.items[0].file.original_name).ext"/>
        </div>
      </template>
      <template v-slot:bottom>
        <div class="card-bottom__top d-flex mb-1">
          <v-tooltip top color="accent">
            <template v-slot:activator="{ on, attrs }">
              <h6
                v-bind="attrs"
                v-on="on"
                class="card-bottom__title font-weight-semi-bold text-truncate"
                v-show="!design.showRenameInput"
                @click.prevent="getPermission($route.params.project_id).design['can-rename-style-design'] ? design.showRenameInput = true : $event.preventDefault
              ">
                {{design.title}}
              </h6>
            </template>
            <span class="white--text font-weight-medium">{{design.title}}</span>
          </v-tooltip>
          <UiChangeNameInput
            v-if="getPermission($route.params.project_id).design['can-rename-style-design'] && design.showRenameInput"
            v-model="design.newTitle"
            placeholder="Enter design style"
            :rules="'required|min:3|max:128'"
            save-on-blur
            @onSave="updateDesignStyle(design)"
            @onClose="design.showRenameInput = false"
            class="fill-width"
          />
          <div v-if="design.items.length && !design.showRenameInput" class="flex-shrink-0 d-flex align-center">
            <IconDoubleChevronRight width="10" class="mx-3 accent-30--text"/>
            <v-tooltip top color="accent">
              <template v-slot:activator="{ on, attrs }">
                <strong v-bind="attrs" v-on="on" class="d-flex align-center text-body font-weight-semi-bold accent-100--text">V: {{design.items[0].version}}</strong>
              </template>
              <span class="white--text font-weight-medium">V{{design.items[0].version}}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="card-bottom__content d-flex justify-space-between flex-wrap">
          <div class="card-bottom__content__left">
            <template v-if="design.items.length">
              <div>Updated: {{timeDistance(design.items[0].updated_at)}}</div>
            </template>
          </div>
          <div class="card-bottom__content__right">
            <SingleItemDueDate
              v-if="$vuetify.breakpoint.smAndUp && design.status !== $config.project.status.approved"
              type="module-style"
              :active-style="moduleStyle(design.id)"
              :selected="getProjectDesigns?.filter(el => el.checked) || []"
              calendar-view-type="inline"
              @updateData="updateDesignDueDate"
            >
              <template v-slot:activator="{attrs, on, dueDaysLeft }">
                <v-btn
                  class="due-date-btn"
                  :color="dueDaysLeft.backgroundColor"
                  v-bind="attrs"
                  v-on="on"
                  :elevation="0"
                  @click.prevent
                >
                  <span class="text-captions-2" :class="[dueDaysLeft.textColor]">{{ dueDaysLeft.prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[dueDaysLeft.textColor]">
                  {{ dueDaysLeft.value }} {{dueDaysLeft.suffix }}
                </span>
                </v-btn>
              </template>
            </SingleItemDueDate>
          </div>
        </div>
        <div class="card-bottom__actions d-flex justify-space-between flex-wrap mt-2">
          <v-tooltip v-if="design.items.length" top color="accent" :disabled="$vuetify.breakpoint.smAndDown">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <UISelectAssignee
                  v-if="design.items.length"
                  v-model="design.items[0].assignee"
                  :assignee-list="assigneeList"
                  :disabled="!getPermission($route.params.project_id).design['can-assignee-user-design']"
                  @input="updateAssignee(design)"
                >
                  <template v-slot:input="{ assignedUser }">
                    <div v-if="assignedUser" class="d-flex align-center text-no-wrap text-captions-1" @click.prevent>
                      By:
                      <UiAvatar
                        :src="assignedUser.user.avatar ? assignedUser.user.avatar.url : ''"
                        :text="assignedUser.user.first_name"
                        size="22"
                        class="ml-2"
                      />
                    </div>
                  </template>
                </UISelectAssignee>
              </div>
            </template>
            <span class="white--text font-weight-medium">{{ design.items[0].assignee.first_name }} {{ design.items[0].assignee.last_name }}</span>
          </v-tooltip>
          <div class="ml-auto">
            <UiBtn
              color="gray-60"
              class="mr-4"
              icon
              :disabled="!design.items.length"
              :loading="design.fileDownloading"
              @click.prevent="$emit('downloadFile', design)">
              <IconDownload width="14"/>
            </UiBtn>

            <UiBtn color="gray-60" class="mr-4" icon disabled>
              <IconChat width="14"/>
            </UiBtn>

            <DesignItemActionsMenu :design="design" :disabled="!getProject?.scheme?.settings[activeModuleWorkflowOpenTypeSlug] && !design.ready_to_work" @updateDesignsData="$emit('updateDesignsData')"/>
          </div>
        </div>
        <div v-if="design.loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
             style="z-index: 2;" @click.prevent>
          <v-progress-circular
            :size="64"
            color="accent"
            indeterminate
          />
        </div>
      </template>
    </UiGridCard>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiGridCard from "@/components/UI/UiGridCard"
import UIImage from "@/components/UI/UIImage";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";
import UISelectAssignee from "@/components/UI/UISelectAssignee";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput";
import DesignItemActionsMenu from "./DesignItemActionsMenu";
import {differenceInDays, format, formatDistanceToNow} from "date-fns";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";
import {getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";
import SingleItemDueDate from "@/views/project/design-list/SingleItemDueDate.vue";
// import UiBadge from "@/components/UI/UiBadge.vue";
  export default {
    name: 'GridView',
    components: {
      SingleItemDueDate,
      // UiBadge,
      UiFileIcon,
      IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
      IconDownload: () => import('@/components/icons/IconDownload'),
      IconChat: () => import('@/components/icons/IconChat'),

      UiGridCard,
      UIImage,
      UiAvatar,
      UISelectAssignee,
      UiSelectedIndicator,
      UiChangeNameInput,
      UiBtn,
      DesignItemActionsMenu,
    },
    props: {
      size: {
        type: String,
        default: 'large'
      }
    },
    computed: {
      ...mapGetters([
        'getProject',
        'getProjectDesigns',
        'getActiveDesignModule',
        'getPermission',
      ]),
      assigneeList() {
        const assignees = []

        this.getProject.team.forEach(user => {
          user.user_role.forEach(role => {
            if(this.$config.project.userRoleByDesignModules[role].includes(this.getActiveDesignModule.slug)) {
              assignees.push(user)
            }
          })
        })

        return assignees
      },
      activeModuleWorkflowOpenTypeSlug() {
        return this.$config.project.workflowOpenTypesById[this.getActiveDesignModule?.workflow_type]
      },
    },
    methods: {
      format,
      getFileThumbnails,
      getFileExtension,
      getFileName,
      onSelect(data) {
        const {id, checked} = data;
        const designs = this.getProjectDesigns.map(design => design.id === id ? {...design, checked} : design);
        this.$store.dispatch('setProjectDesigns', designs);
      },
      timeDistance(date) {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '')
      },
      async updateDesignStyle(design) {
        design.loading = true;

        try {
          const res = await this.$api.projectModuleDesign.updateDesignStyleName(
            this.$route.params.project_id, this.$store.getters.getActiveDesignModule.id, design.id, {title: design.newTitle}
          );
          design.title = res.data.title;
        } catch (error) {
          console.error(error);
        } finally {
          design.loading = false;
          design.showRenameInput = false;
        }
      },
      updateAssignee(design) {
        design.loading = true;
        this.$api.projectModuleDesign.updateAssignee(
          this.$route.params.project_id, this.getActiveDesignModule.id, design.id, design.items[0].id, {assignee_id: design.items[0].assignee.id}
        ).then(res => {
          design.items[0].assignee = {...design.items[0].assignee, ...res.data.assignee};
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          design.loading = false;
        })
      },
      go(design) {
        if (design.showRenameInput) return;
        return {
          name: 'DesignViewPage',
          params: {
            project_id: this.$route.params.project_id,
          },
          query: {
            module_id: this.getActiveDesignModule.id,
            style_id: design.id,
            file_id: design.items.length ? design.items[0].id : '',
            gallery_group_id: design.items.length ? design.items[0].gallery_group_id : '',
          }
        };
      },
      moduleStyle(styleId) {
        return this.getActiveDesignModule?.styles?.find(style => style.id === Number(styleId))
      },
      async updateDesignDueDate() {
        this.$emit('updateAllData')
      }
    }
  }
</script>

<style lang="scss" scoped>
.card-wr {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  &.large {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  &.medium {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  &.small {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
.card-image {
  background: var(--v-gray-30-base);
  img {
    object-fit: cover;
  }
}
.card-bottom {
  &__title {
    font-size: 14px;
    color: var(--v-gray-100-base);
  }
  &__content {
    font-size: 12px;
    color: var(--v-gray-60-base);
    &__right {
      color: var(--v-gray-80-base);
      .dark {
        color: var(--v-gray-100-base);

      }
    }
  }
  &__actions {
    &__avatar {
      border-color: var(--v-accent-base) !important;
      &:nth-child(2) {
        border-color: var(--v-accent-60-base) !important;
      }
    }
    .v-btn.v-btn--disabled {
      opacity: .57;
    }
  }
}

.due-date-btn {
  max-height: 18px;
  padding: 4px !important;
  border-right: 3px !important;
}
</style>
