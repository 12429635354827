<template>
  <UiMenu
    v-model="menu"
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
    :close-on-content-click="false"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" @click.prevent icon :disabled="disabled" color="gray-60">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>

    <v-list nav dense color="gray-10">
      <v-list-item
        :disabled="!getPermissionByModule('can-upload-file-design')"
      >
        <v-list-item-title class="text-captions-1">
          <UIFilesUploader
            :files-formats="$config.project.modulesFileFormats[getActiveDesignModule.slug]"
            :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize"
            :disabled="!getPermissionByModule('can-upload-file-design')"
            :drag-and-drop="false"
            @onChange="createFile"
            class="d-flex align-center overflow-hidden cursor-pointer"
          >
            <IconUpload width="15" class="mr-2 gray-60--text"/>
            Upload file
          </UIFilesUploader>
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :disabled="!abilityToRequestReview"
        @click="requestReview"
      >
        <v-list-item-title class="text-captions-1">
          <IconSendMessage width="15" class="mr-2 gray-60--text"/>
          Request review
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :disabled="!checkIfDisabled($config.project.status.rejected) || !abilityToReview"
        @click="approveOrReject($config.project.status.rejected)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCancel width="15" class="mr-2 gray-60--text"/>
          Request adjustments
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :disabled="!checkIfDisabled($config.project.status.approved) || !abilityToReview"
        @click="approveOrReject($config.project.status.approved)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCheck width="15" class="mr-2 gray-60--text"/>
          Approve design
        </v-list-item-title>
      </v-list-item>

      <v-list-item :disabled="!getPermission($route.params.project_id).design['can-share-design'] || true">
        <v-list-item-title class="text-captions-1">
          <IconShare width="15" class="mr-2 gray-60--text"/>
          Share file
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :disabled="!checkIfDisabled(isArchived ? $config.project.status.in_progress : $config.project.status.archived) || !getPermissionByModule('can-archive-design')"
        @click="updateStatus(isArchived ? $config.project.status.in_progress : $config.project.status.archived)"
      >
        <v-list-item-title class="text-captions-1">
          <IconArchive width="15" class="mr-2 gray-60--text"/>
          {{ isArchived ? 'Unarchive' : 'Archive' }} file
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :disabled="!getPermission($route.params.project_id).design['can-delete-style-design']"
        @click="deleteDesign"
      >
        <v-list-item-title class="text-captions-1">
          <IconDelete width="15" class="mr-2 gray-60--text"/>
          Delete style
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!deleteDisable"
        @click="deleteItems"
      >
        <v-list-item-title class="text-captions-1">
          <IconDelete width="15" class="mr-2 gray-60--text"/>
          Delete file
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import {getFileExtension} from "@/utils/helpers";

export default {
  name: 'DesignItemActionsMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconSendMessage: () => import('@/components/icons/IconSendMessage'),
    IconCheck: () => import('@/components/icons/IconCheck'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconUpload: () => import('@/components/icons/IconUpload'),
    IconShare: () => import('@/components/icons/IconShare'),
    IconArchive: () => import('@/components/icons/IconArchive'),
    IconDelete: () => import('@/components/icons/IconDelete'),

    UiMenu,
    UiBtn,
    UIFilesUploader,
  },
  props: {
    design: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getActiveDesignModule',
      'getProjectDesigns',
      'getProjectPermissionData',
      'getPermission',
      'getAllProjectStyles',
      'getProjectDesignsFilters'
    ]),
    abilityToRequestReview() {
      return this.design.items.length > 0 && (this.design.items[0].status !== this.$config.project.status.approved && this.getPermissionByModule('can-request-review-design') || this.isManagerOrOwner);
    },
    abilityToReview() {
      return this.design.items.length > 0 && (this.design.items[0].request_reviews.find(user => user.user_id === this.getAuthId) || this.isManagerOrOwner);
    },
    isArchived() {
      return this.design.items.length && this.design.items[0].status === this.$config.project.status.archived;
    },
    deleteDisable() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return this.design.items.length && ((this.design.items[0].created_by === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager)
      ) && this.getPermissionByModule('can-delete-file-design'));
    },
    isManagerOrOwner() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager);
    }
  },
  methods: {
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(this.getActiveDesignModule.slug);
    },
    checkIfDisabled(status) {
      return this.design.items.length > 0 && this.design.items[0].status !== status;
    },
    createFile(files) {
      this.menu = false;

      const {module} = this.getProjectDesignsFilters;
      let formData = new FormData();

      if (this.getActiveDesignModule.slug === this.$config.project.modules.cadDesign) {
        const cadAllowedType = [...this.$config.project.modulesFilesFormatGroup['cad-design'][1]]
        const cadFiles = []

        files.forEach(file => {

          if (cadAllowedType.includes(getFileExtension(file.name))) {
            cadFiles.push(file)
          }
        })

        Object.values(this.design.grouped_items || {}).forEach(([file]) => {

          if (cadAllowedType.includes(getFileExtension(file.file.original_name))) {
            cadFiles.push(file)
          }
        })

        // if (cadFiles.length > 1) {
        //   this.$store.dispatch('openModal', {
        //     modalName: 'fileUploadErrorModal',
        //     data: {
        //       type: 'limitError',
        //     }
        //   });
        //   return
        // }
      }

      const tags = this.design?.tags?.map(tag => tag.id) || [];
      files.forEach((file, idx) => {
        formData.append(`files[${idx}][file]`, file);
        formData.append(`files[${idx}][style_id]`, this.design.id);
        tags.forEach(tag => {
          formData.append(`files[${idx}][tags][]`, tag);
        });
      });

      this.design.loading = true;

      this.$api.projectModuleDesign.create(this.$route.params.project_id, module, formData)
        .then(res => {
          const newDesignItem = res.data[0].items[res.data[0].items.length - 1];
          this.$router.push({
            name: 'DesignViewPage',
            params: {
              project_id: this.$route.params.project_id,
            },
            query: {
              module_id: this.getActiveDesignModule.id,
              style_id: res.data[0].id,
              file_id: newDesignItem.id,
            }
          })
        });
    },
    async updateStatus(status) {
      if (!this.getPermissionByModule('can-archive-design')) return;

      this.menu = false;
      this.design.loading = true;

      try {
        const res = await this.$api.projectModuleDesign.updateMultipleItemsStatus(this.$route.params.project_id, this.getActiveDesignModule.id, {
          status: status,
          items: [this.design.items[0].id],
        });

        this.design.items[0].status = status;
        this.design.items[0].updated_at = res.data[0].updated_at;
      } catch (error) {
        console.error(error);
      } finally {
        this.design.checked = false;
        this.design.loading = false;
      }
    },
    requestReview() {
      if (!this.abilityToRequestReview) return;

      this.$store.dispatch('openModal', {
        modalName: 'requestReviewModal',
        data: {
          files: [this.design],
          module_id: this.getActiveDesignModule.id,
        },
      });

      this.menu = false;
    },
    approveOrReject(status) {
      this.$store.dispatch('openModal', {
        modalName: 'approveRejectModal',
        data: {
          status,
          files: [this.design.items[0]],
        },
        handlers: {
          onUpdate: () => {
            this.$emit('updateDesignsData')
          },
        }

      });
    },
    deleteDesign() {
      if (!this.getPermission(this.$route.params.project_id).design['can-delete-style-design']) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete <br> “${this.design.title}“?`,
          confirmBtnText: 'Delete'
        },
        handlers: {
          onConfirm: async () => {
            this.design.loading = true;
            await this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.projectModuleDesign.deleteMultiple(this.$route.params.project_id, this.getActiveDesignModule.id, {styles: [this.design.id]});
              const designs = this.getProjectDesigns.filter(design => design.id !== this.design.id);
              const allStyles = this.getAllProjectStyles.filter(style => style.id !== this.design.id);

              if (designs.length === 0) {
                this.$store.state.ProjectDesign.noCreatedStyles = true;
              }
              this.$store.dispatch('setProjectDesigns', designs);
              this.$store.dispatch('setAllProjectStyles', allStyles);
            } catch(error) {
              console.error(error);
            } finally {
              // we need to load the project to check if the module has files
              // if module don't have any files, it will be available to remove
              const project = await this.$api.project.get(this.$route.params.project_id);
              this.$store.dispatch('setProject', project.data);
              this.$store.dispatch('setDesignModules', project.data.modules);


              this.design.loading = false;
            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          }
        }
      });

      this.menu = false;
    },
    deleteItems() {
      if (!this.deleteDisable) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete this file?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: async () => {
            this.design.loading = true;
            await this.$store.dispatch('closeModal', 'confirmModal');

            try {
              await this.$api.projectModuleDesign.deleteFiles(this.$route.params.project_id, this.getActiveDesignModule.id, {items: [this.design.items[0].id]});
              const currentDesign  = await this.$api.projectModuleDesign.filesList(this.$route.params.project_id, this.getActiveDesignModule.id, this.design.id);
              const designs = this.getProjectDesigns.map(design => {
                return {
                  ...design,
                  items: design.id === this.design.id ? currentDesign.data.items: design.items
                }
              });
              await this.$store.dispatch('setProjectDesigns', designs);
            } catch (error) {
              console.error(error);
            }
            finally {
              const project = await this.$api.project.get(this.$route.params.project_id);
              this.$store.dispatch('setProject', project.data);
              this.$store.dispatch('setDesignModules', project.data.modules);

              this.design.loading = false;

            }
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
        },
      });
    },
  },
}
</script>
